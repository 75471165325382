import React from 'react'
import { Link } from 'gatsby'
import Button from '../components/Button'
import Card from '../components/Card'
import GradientContainer from '../components/GradientContainer'
import NavigationBar from '../components/NavigationBar'

const NotFoundPage = () => {
  return (
    <div>
      <GradientContainer>
        <div className="container mx-auto">
          <div className="md:mb-24">
            <NavigationBar />
            <main className='flex flex-col items-center justify-center w-full h-full pt-12'>
              <Card classes='bg-dark' x={20} y={20}>
                <div className='flex flex-col items-center gap-8'>
                  <h1 className='text-4xl font-light text-primary'>Page not found</h1>
                  <p className='text-center'>Sorry, we couldn't find the page you're looking for</p>
                  <Link to='/'>
                    <Button type='primary'>
                      Return home
                    </Button>
                  </Link>
                </div>
              </Card>
            </main>
          </div>
        </div>
      </GradientContainer>
    </div>
  )
}

export default NotFoundPage